<template>
  <v-card flat class="segmented">
    <v-card-title>
      <v-row class="align-center">
        <v-col cols="12" md="6">
          <h5>{{ $t('employeeOverview.myEmployeesOverview') }}</h5>
        </v-col>
        <v-col cols="12" md="6" lg="4" offset-lg="2">
          <v-text-field
            v-model="keyword"
            solo
            flat
            hide-details
            :label="$t('searchKeywordPlaceholder')"
            append-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <employees-overview
        :employees="employees"
        :keyword="keyword"
        :loading="dataLoading"
        :detailsRoute="'EmployeeDetails'"
        showSupervisor
        showMentor
      ></employees-overview>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import employeesOverview from '@/components/employeeDetails/employees-table.vue';

export default {
  components: {
    employeesOverview
  },

  data: () => ({
    keyword: '',
    dataLoading: true
  }),

  computed: {
    ...mapGetters({
      employees: 'users/getOverviewUsers'
    })
  },

  async mounted() {
    await this.fetchUsers();
    this.dataLoading = false;
  },

  methods: {
    ...mapActions({
      fetchUsers: 'users/fetchMyEmployees'
    })
  }
};
</script>

<style lang="scss" scoped>
.v-data-table tbody tr td:hover {
  cursor: pointer;
}
</style>
